import ResponsiveImage from 'components/core/ResponsiveImage'
import Markdown from 'markdown-to-jsx'
import { TextBlocksWithFeatureImageModel } from 'models/body/TextBlocksWithFeatureImageModel'
import { TextBlockModel } from 'models/core/TextBlockModel'
import { useState } from 'react'

interface Props {
  contentBlock: TextBlocksWithFeatureImageModel
}
export default function ServiceSection(props: Props) {
  const [firstBlock, setFirstBlock] = useState<TextBlockModel>(props.contentBlock.textBlocks[0])
  const [secondBlock, setSecondBlock] = useState<TextBlockModel>(props.contentBlock.textBlocks[3])
  return (
    <section className="encrypted-service bg-[#181436] py-10 md:py-20 xl:py-28">
      <div className="container !max-w-[1300px]">
        <h2 className="text-white text-center">
          <Markdown>{props.contentBlock.content}</Markdown>
        </h2>
        <div className="service-item flex flex-wrap items-center lg:min-h-[504px]">
          <div className="text-block w-full lg:w-1/2">
            {props.contentBlock.textBlocks.slice(0, 3).map((block) => {
              const sanitizedKey = block.title.replace(/[^a-zA-Z0-9]/g, '_').toLocaleLowerCase()
              return (
                <div
                  onMouseEnter={() => setFirstBlock(block)}
                  key={sanitizedKey}
                  className="text-block-item mb-20 md:mb-0 text-base leading-7 text-white relative before:border-[#9280FF1A]/10 before:border-l-4 before:content-[''] 
            before:w-1 before:h-[calc(100%-50px)] before:transition before:duration-300 before:absolute before:-left-8 before:z-10 before:top-[50px] cursor-pointer"
                >
                  <h3 className="mb-5 md:mb-0">
                    <a>{block.title}</a>
                  </h3>
                  <div className="w-full">
                    <Markdown>{block.content}</Markdown>
                  </div>
                  {block.link && block.linkDisplay && (
                    <a href={block.link} className="btn-link">
                      {block.linkDisplay}
                    </a>
                  )}
                  <div className="mobile-img lg:hidden mt-10 flex justify-center animate-fade">
                    <ResponsiveImage data={block.image} extraClassNames="animate-fade" />
                  </div>
                </div>
              )
            })}
          </div>
          <div className="img-block w-full lg:w-1/2 justify-center hidden lg:flex">
            <ResponsiveImage data={firstBlock.image} extraClassNames="animate-fade lg:min-w-[540px]" />
          </div>
        </div>
        <div className="service-item flex flex-wrap items-center">
          <div className="text-block w-full lg:w-1/2">
            {props.contentBlock.textBlocks.slice(3, 6).map((block) => {
              const sanitizedKey = block.title.replace(/[^a-zA-Z0-9]/g, '_').toLocaleLowerCase()

              return (
                <div
                  onMouseEnter={() => setSecondBlock(block)}
                  key={sanitizedKey}
                  className="text-block-item text-base leading-7 text-white relative before:border-[#9280FF1A]/10 before:border-l-4 before:content-[''] 
            before:w-1 before:h-[calc(100%-50px)] before:transition before:duration-300 before:absolute before:-left-8 before:z-10 before:top-[50px] cursor-pointer"
                >
                  <h3>
                    <a>{block.title}</a>
                  </h3>
                  <div className="w-full">
                    <Markdown>{block.content}</Markdown>
                  </div>
                  {block.link && block.linkDisplay && (
                    <a href={block.link} className="btn-link">
                      {block.linkDisplay}
                    </a>
                  )}
                  <div className="mobile-img lg:hidden mt-10 flex justify-center">
                    <ResponsiveImage data={block.image} extraClassNames="animate-fade" />
                  </div>
                </div>
              )
            })}
          </div>
          <div className="img-block w-full lg:w-1/2 hidden lg:flex">
            <ResponsiveImage data={secondBlock.image} extraClassNames="animate-fade" />
          </div>
        </div>
      </div>
    </section>
  )
}
