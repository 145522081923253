import ResponsiveImage from 'components/core/ResponsiveImage'
import Markdown from 'markdown-to-jsx'
import { TextBlocksWithFeatureImageModel } from 'models/body/TextBlocksWithFeatureImageModel'

interface Props {
  contentBlock: TextBlocksWithFeatureImageModel
}
export default function TextWithImage(props: Props) {
  const contentBlockOne = props.contentBlock.textBlocks[0]
  const contentBlockTwo = props.contentBlock.textBlocks[1]
  return (
    <section className="TextWithImage-section py-10 md:py-28">
      <div className="container !max-w-[1300px]">
        <div className="TextWithImage-item">
          <div className="w-full md:w-1/2">
            <h3 className="text-[#001064] text-[32px] leading-[48px] font-bold mb-0 pb-5 md:max-w-[480px]">{contentBlockOne.title}</h3>
            <p className="pb-0 mb-0">
              <b>{contentBlockOne.subtitle}</b>
            </p>
            <Markdown>{contentBlockOne.content}</Markdown>

            <a href={contentBlockOne.link} className="inline-block text-base px-8 py-2 font-semibold mt-10 btn-light">
              {contentBlockOne.linkDisplay}
            </a>
          </div>
          <div className="w-full md:w-1/2">
            <ResponsiveImage extraClassNames="w-full" data={contentBlockOne.image} />
          </div>
        </div>
        <div className="TextWithImage-item ">
          <div className="w-full md:w-1/2">
            <ResponsiveImage extraClassNames="w-full" data={contentBlockTwo.image} />
          </div>
          <div className="w-full md:w-1/2">
            <h3 className="text-[#001064] text-[32px] leading-[48px] font-bold mb-0 pb-5 md:max-w-[480px]">{contentBlockTwo.title}</h3>
            <Markdown>{contentBlockTwo.content}</Markdown>
            <a href={contentBlockTwo.link} className="link-icon">
              {contentBlockTwo.linkDisplay}
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
