import ResponsiveImage from 'components/core/ResponsiveImage'
import Slider from 'react-slick'
import { CustomerReviews } from 'types/generatedTypes'

export default function CarouselSlider({
  reviews,
}: {
  //types are generated from JSON through https://app.quicktype.io/ and kept in types/geneeratedTypes file
  reviews: CustomerReviews[] | undefined
}) {
  const sliderSettings = {
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <section className="py-10 md:py-20 xl:py-28 mb-20 md:mb-0">
      <div className="container 2xl:max-w-[1300px] font-Manrope font-medium text-[#001064] text-base text-center">
        <h2 className="text-[#001064]">Trusted by tech experts and users</h2>
        <p>Is SecureMyEmail the best Email service? Let’s ask our users and tech experts.</p>
      </div>
      <div className="user-slider lg:px-32 pt-5 relative">
        <Slider {...sliderSettings}>
          {reviews?.map((item) => (
            <div key={item.id} className="user-slider-item">
              <span className="mb-5 text-xl">{item?.title.slice(0, 200)}...</span>
              <div className="h-full justify-end">
                <ResponsiveImage data={item?.image} />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}
