import { LocalImage } from 'components/core/CdnImage'
import ResponsiveImage from 'components/core/ResponsiveImage'
import Markdown from 'markdown-to-jsx'
import { HomeHeroModel } from 'models/hero/HomeHeroModel'
interface Props {
  data: HomeHeroModel
}
export default function HomeHeroSection(props: Props) {
  return (
    <section id="any-hero" className="hero min-h-screen pt-[74px] md:pt-56 pb-14 relative overflow-hidden">
      <div className="container !max-w-[1300px] z-40 relative flex items-center flex-col-reverse lg:flex-row pt-10 md:pt-0">
        <div className="text-box w-full md:max-w-[530px] font-manrope font-normal text-white text-[13px]">
          <h1 className="text-white">
            <Markdown>{props?.data?.title}</Markdown>
          </h1>
          <h3>
            <Markdown>{props?.data?.subtitle}</Markdown>
          </h3>
          <a href={props?.data?.buttons[0]?.link} className="btn-orange">
            {props?.data?.buttons[0]?.text}
          </a>
          <p> {props?.data?.buttonSubText}</p>
          <div className="w-full mt-20 grid grid-cols-2 gap-4 hero-bottom">
            <div className="anchorblock min-w-[200px]">
              <div className="flex flex-col flex-wrap">
                <div className="text-[13px] text-white leading-6 mb-2">Everyone is saying…</div>
                <div className="w-full">
                  <a className="brand-row" href="#reviews">
                    {props?.data?.brandButtons.map((btn, idx) => (
                      <div key={idx} className="brand-item">
                        <div role="tooltip" className="tooltip bounce-in">
                          <ResponsiveImage extraClassNames="min-h-[14px]" data={btn?.icon} />
                        </div>
                        <ResponsiveImage data={btn?.backgroundImage} extraClassNames="w-[40px] h-[40px] rounded" />
                      </div>
                    ))}
                  </a>
                </div>
              </div>
            </div>
            <div className="app_rating relative">
              <div className="text-[13px] text-white leading-6 mb-6">App Rating</div>
              <ul className="flex gap-1.5">
                <li>
                  <LocalImage fileName="ic-star.svg" alt="star" />
                </li>
                <li>
                  <LocalImage fileName="ic-star.svg" alt="star" />
                </li>
                <li>
                  <LocalImage fileName="ic-star.svg" alt="star" />
                </li>
                <li>
                  <LocalImage fileName="ic-star.svg" alt="star" />
                </li>
                <li>
                  <LocalImage fileName="ic-star.svg" alt="star" />
                </li>
                <span>5 star</span>
              </ul>
            </div>
          </div>
        </div>
        <div className="img-wrap w-full lg:w-[960px] lg:-mr-[220px] flex justify-center items-start">
          <div className="img-desktop relative">
            <ResponsiveImage data={props?.data?.featureImage} />
          </div>
        </div>
      </div>
    </section>
  )
}
