import Markdown from 'markdown-to-jsx'
import { FaqCollectionModel } from 'models/body/FaqCollectionModel'
import { TextWithCtaButtonsModel } from 'models/body/TextWithCtaButtonsModel'
import { SingleTextHeroModel } from 'models/hero/SingleTextHeroModel'
import { PageModel } from 'models/page/PageModel'

interface Props {
  faqs: FaqCollectionModel
  ctaData: TextWithCtaButtonsModel
}
export default function Faq(props: Props) {
  return (
    <section className="faq-section pt-10 md:pt-[120px]">
      <div className="container !max-w-[1300px]">
        <h2 className="text-[#001064] text-3xl md:text-[32px] md:leading-[42px] font-Manrope text-center mb-14 font-bold p-0">Find the answers you need</h2>
        <div className="flex flex-col divide-y max-w-[750px] mx-auto divide-[#D5D5D5]">
          {props?.faqs.items
            .filter((f) => f.isFeatured == true)
            .slice(0, 6)
            .map((faq) => {
              const sanitizedKey = faq.question.replace(/[^a-zA-Z0-9]/g, '_').toLocaleLowerCase()
              return (
                <details key={sanitizedKey}>
                  <summary className="py-4 outline-none cursor-pointer text-[#001064] text-xl font-Manrope leading-[30px] font-bold">{faq.question}</summary>
                  <div className="px-4 pb-4 text-base text-[#001064] leading-[24px] font-Manrope font-normal">
                    <Markdown>{faq.answer}</Markdown>
                  </div>
                </details>
              )
            })}
        </div>
        <div className="faq-cta mt-10 md:my-16">
          <div className="max-w-[750px] mx-auto">
            <div className="md:flex items-center justify-between">
              <div className="w-full md:w-3/5">
                <h3 className="text-[#9280FF] text-xl font-bold font-Manrope leading-[38px]">{props.ctaData.title}</h3>
                <p>{props.ctaData.subtitle}</p>
              </div>
              <div className="w-full md:w-2/5 flex md:justify-end">
                <a href={props.ctaData.button[0].link} className="bg-[#F48121] hover:opacity-90 rounded-full text-white text-base px-10 py-4 font-semibold">
                  {props.ctaData.button[0].text}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
