import { LocalImage } from 'components/core/CdnImage'
import ResponsiveImage from 'components/core/ResponsiveImage'
import { HomeHeroModel } from 'models/hero/HomeHeroModel'

interface Props {
  data: HomeHeroModel
}

export default function AppStoreSection(props: Props) {
  return (
    <section className="w-full">
      <div className="container 2xl:max-w-[1300px]">
        <div className="w-full max-w-[820px] mx-auto">
          <h3 className="font-medium text-2xl text-[#001064] w-full max-w-[686px] mx-auto mb-10 text-center font-Manrope">{props?.data?.appStoreTitle}</h3>
          <div className="app-list">
            <div className="grid grid-flow-row grid-cols-2 md:grid-cols-4 gap-3 w-full">
              {props?.data?.appStoreButtons.map((btn) => (
                <a key={btn?.htmlId} href={btn?.link} id="app-store-test-id">
                  <ResponsiveImage extraClassNames="cursor-pointer w-48" data={btn?.backgroundImage} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
