import { LocalImage } from 'components/core/CdnImage'
import ResponsiveImage from 'components/core/ResponsiveImage'
import Markdown from 'markdown-to-jsx'
import { TextWithCtaButtonsModel } from 'models/body/TextWithCtaButtonsModel'

interface Props {
  data: TextWithCtaButtonsModel
}
export default function MediumOrchidCta(props: Props) {
  return (
    <section className="orchidcta-section">
      <div className="container !max-w-[1300px]">
        <div className="flex-col-reverse flex lg:flex-row bg-[#8264EB] rounded-[35px] text-white px-10 py-10 lg:px-[85px] lg:py-[75px] font-Manrope">
          <div className="w-full lg:w-1/2">
            <LocalImage className="mb-4" width={40} height={40} fileName="sme-icon.svg" alt="securemyemail" />
            <p className="pb-3">{props.data.title}</p>
            <h4 className="text-[32px] md:text-[40px] md:leading-[50px] text-white font-bold mb-12 font-Manrope">
              <Markdown>{props.data.content}</Markdown>
            </h4>

            <a href={props.data.button[0].link} className="bg-[#F48121] inline-block hover:opacity-90 rounded-full text-white text-base px-5 py-4 font-semibold mb-3">
              {props.data.button[0].text}
            </a>
            <span className="info text-[13px]">{props.data.subtitle}</span>
          </div>
          <div className="w-full lg:w-1/2 flex justify-center lg:justify-end mb-10 md:mb-0">
            <ResponsiveImage data={props.data.featureImage} extraClassNames="lg:min-w-[418px]" />
          </div>
        </div>
      </div>
    </section>
  )
}
