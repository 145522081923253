import { LocalImage } from 'components/core/CdnImage'
import ResponsiveImage from 'components/core/ResponsiveImage'
import Markdown from 'markdown-to-jsx'
import { TextWithIconsModel } from 'models/body/TextWithIconsModel'
interface Props {
  iconsData: TextWithIconsModel
}

export default function IndividualSection(props: Props) {
  return (
    <section className="py-10 md:py-20 xl:py-28">
      <div className="container !max-w-[1300px] font-Manrope font-normal">
        <div className="cta-block bg-[#181436] rounded-[35px] text-center py-[76px] md:px-20 px-3 text-white">
          <ul className="flex-col md:flex-row flex items-center justify-center gap-7 mb-16">
            {props?.iconsData?.icons.map((icon) => (
              <li key={icon?.title}>
                <ResponsiveImage data={icon?.image} />
                <label className="font-medium text-base mt-4 block">{icon?.title}</label>
              </li>
            ))}
          </ul>
          <h2 className="font-Manrope text-white font-bold text-[40px] leading-[1.35em]">{props?.iconsData?.title}</h2>
          <h3 className="font-Manrope text-white font-normal text-2xl w-full max-w-[830px] mx-auto mb-12">{props?.iconsData?.subtitle} </h3>
          <a href={`${props?.iconsData?.link}`} className="btn-white inline-block">
            {props?.iconsData?.linkDisplay}
          </a>
        </div>
        <div className="half-text-image mt-10 md:mt-20 xl:mt-28">
          <h2 className="text-[#001064] text-[28px] md:text-[32px] w-full md:max-w-[540px] mb-5"> {props?.iconsData?.textBlock?.title}</h2>
          <div className="md:flex justify-between items-center gap-8">
            <div className="img-box max-w-[570px] w-full mb-5">
              <ResponsiveImage data={props?.iconsData?.textBlock?.image} />
            </div>
            <div className="text-box text-[#001064] max-w-[560px] w-full text-base font-normal leading-7">
              <h4 className="font-normal text-[22px] leading-8">{props?.iconsData?.textBlock?.subtitle}</h4>

              <Markdown>{props?.iconsData?.textBlock?.content}</Markdown>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
